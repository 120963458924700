import React, {useState} from 'react'
import './Slider.css'
/* import BtnSlider from './BtnSlider' */
import { v4 as uuidv4 } from "uuid";
import leftArrow from "./icons/left-arrow.svg";
import rightArrow from "./icons/right-arrow.svg";
/* import dataSlider from './dataSlider' */

export default function Slider() {
    const dataSlider = [
        {
          id: uuidv4(),
          title: "",
          subTitle: ""
        },
        {
            id: uuidv4(),
            title: "",
            subTitle: ""
          }
      ];


    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <div className="container-slider ">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <img
                        src={require(`./images/index-cover${index + 1}.jpg`)}
                        />
                    </div>
                )
            })}
            <button
            onClick={nextSlide}
            className="btn-slide next"
            >
            <img src={rightArrow} />
            </button>
            <button
            onClick={prevSlide}
            className="btn-slide prev"
            >
            <img src={leftArrow} />
            </button>
            {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}

            <div className="container-dots">
                {dataSlider.map((item, index) => (
                    <div
                    key={index}
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    >

                    </div>
                ))}
            </div>
            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "txt-container active abosulte top-36 w-full" : "txt-container " }
                    >
                      <h5 className='text-theme-color-2 text-3xl md:text-6xl'>{obj.title}</h5>
                      <div className='mt-8 text-2xl md:ml-96'>
                        <span className='text-theme-color-2 md:text-5xl'>{obj.subTitle}</span>
                      </div>


                    </div>
                )
            })}
        </div>
    )
}
