import React from 'react'
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import { treatmentQuestionArray, treatmentPlanArray, MetaDataList } from '../../components/Data'
import backgroundImage from '../../components/Carousel/images/index-cover2.jpg'
import {
    CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { useParams } from 'react-router-dom';
import VideoContainer from '../../components/VideoContainer';

export default function Treatment({ service, description }) {
    const pageAlias = 'service-digital-marketing'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service/digital-marketing` });
    const { treatment } = useParams();

    return (
        <DocumentMeta {...meta}>
            <section className='relative md:h-[100vh] pt-[100px] pb-16 md:pb-0'>
                <img className="absolute opacity-50 bg-black inset-0 w-full h-full object-cover -z-10"
                    src={backgroundImage}
                />
                <div className='section-container max-w-[1200px] mx-auto px-[30px] text-center text-theme-color-2 font-bold wow fadeIn'>
                    <h1 className='mb-8 text-5xl text-theme-color-3 '>{treatmentPlanArray[treatment].title}</h1>
                </div>
            </section>
            {treatmentPlanArray[treatment].iframe !== '' && (
                <section className='bg-transparent md:-mt-[500px]'>
                    <div className='section-container mx-auto px-[30px]'>
                        <VideoContainer planList={treatmentPlanArray[treatment].iframe} />
                    </div>
                </section>
            )}

            <section className='py-[70px] bg-theme-color-4'>
                <div className='section-container mx-auto px-[30px]'>
                    <div className='grid md:grid-cols-2 gap-[30px]'>
                        <div className='bg-theme-color-1 px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                    <CheckCircleIcon className='w-12 text-theme-color-3' />
                                </div>
                                <div className='ml-[20px]'>
                                    <h4 className='text-3xl mb-[5px] text-theme-color-3'>{treatmentPlanArray[treatment].pageTitle1}</h4>
                                </div>
                            </div>
                            <p className='text-xl text-theme-color-3'>{treatmentPlanArray[treatment].pageDetail1}</p>
                        </div>
                        <div className='bg-theme-color-1 px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                <CheckCircleIcon className='w-12 text-theme-color-3' />
                                </div>
                                <div className='ml-[20px]'>
                                <h4 className='text-3xl mb-[5px] text-theme-color-3'>{treatmentPlanArray[treatment].pageTitle2}</h4>
                                </div>
                            </div>
                            <p className='text-xl text-theme-color-3'>{treatmentPlanArray[treatment].pageDetail2}</p>

                        </div>
                        <div className='bg-theme-color-1 px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                <CheckCircleIcon className='w-12 text-theme-color-3' />
                                </div>
                                <div className='ml-[20px]'>
                                <h4 className='text-3xl mb-[5px] text-theme-color-3'>{treatmentPlanArray[treatment].pageTitle3}</h4>
                                </div>
                            </div>
                            <p className='text-xl text-theme-color-3'>{treatmentPlanArray[treatment].pageDetail3}</p>

                        </div>
                        <div className='bg-theme-color-1 px-[30px] py-[40px] transition-all hover:scale-105'>
                            <div className='flex'>
                                <div>
                                <CheckCircleIcon className='w-12 text-theme-color-3' />
                                </div>
                                <div className='ml-[20px]'>
                                <h4 className='text-3xl mb-[5px] text-theme-color-3'>{treatmentPlanArray[treatment].pageTitle4}</h4>
                                </div>
                            </div>
                            <p className='text-xl text-theme-color-3'>{treatmentPlanArray[treatment].pageDetail4}</p>
                        </div>
                    </div>
                </div>
            </section>
            {
                /*
                <section className='bg-theme-color-5 py-[70px]'>
                <div className='section-container max-w-[1300px]'>
                    <h2 className='mb-[50px] text-white text-center'>Q&A</h2>
                    {treatmentQuestionArray.map(({ title, content }) => (
                        <Accordion key={title} title={title} content={content} />
                    ))}
                </div>
                </section>
                */
            }

        </DocumentMeta>
    )
}
