import React from 'react'
import DocumentMeta from 'react-document-meta';
import Slider from '../components/Carousel/Slider'
import MetaTag from "../components/MetaTag";
import Form from '../components/Form'
import SocialMediaShare from '../components/SocialMediaShare'
import Swiper from '../components/Swiper'
import { promoSwiper} from '../components/Data'
import indexImage1 from '../images/indexImage1.jpg'
import indexImage2 from '../images/indexImage2.jpg'
import { ShieldCheckIcon, StarIcon, TrophyIcon } from '@heroicons/react/24/outline'


export default function Home() {
    const pageAlias = 'service-home'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service` });

    return (
        <DocumentMeta {...meta}>
            <section className="container mx-auto py-0 my-0">
                <Slider />
            </section>

            <section className="bg-white bg-no-repeat bg-cover bg-scroll bg-[50%_0] container mx-auto py-8">
                <div className='section-container mx-auto px-[30px]'>
                    <div className=' flex-col-reverse md:flex-row flex items-center justify-between'>
                        <div className='flex-1 text-center md:text-left'>
                            <h2 className='text-3xl md:text-4xl mb-[20px] wow fadeInLeft'><span className='font-bold text-theme-color-4 '>Lux Medical</span> 醫纖美</h2>
                            <div className='wow fadeInLeft '>
                                <p className='text-xl'>創立於2011年，薈萃頂尖中西醫美科技，不單擁有專業西醫及中醫團隊，旗下治療師團隊均擁國際認證專業證書，</p>
                                <p className='text-xl'>融匯嶄新醫美科技，為客人提供專業醫美方案，成就終生美麗。</p>
                            </div>

                            <div className='wow fadeInUp'>
                                <a href="https://api.whatsapp.com/send/?phone=85267776444&text&type=phone_number&app_absent=0" className='effect-cta mt-4'>立即預約</a>
                            </div>
                        </div>
                        <div className='flex-1 md:ml-[30px] mb-[30px] md:mb-0 wow zoomIn'>
                            <img className='mx-auto w-full md:w-3/5 ' src={indexImage1} alt="img" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="relative bg-white text-theme-blue py-[70px] text-center">
            <div className='section-container mx-auto px-[20px]'>
                <h2 className='mb-[50px]'>What we do best</h2>
                <div className='flex flex-wrap justify-center'>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={seoLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>SEO<br/>marketing</p>
                    </div>

                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={digitalMarketingLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Digital<br/>marketing</p>
                    </div>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={designLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Logo<br/>design</p>
                    </div>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={webDesignLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Web<br/>design</p>
                    </div>
                </div>
            </div>
        </section> */}
            <section className="bg-gradient-to-r from-theme-color-1 to-theme-color-4">
                <div className='section-container mx-auto py-8'>
                    <div className=' flex-col md:flex-row flex items-center justify-between'>
                        <div className='flex-1 md:ml-[30px] flex-col mb-[30px] md:mb-0 wow rotateIn'>
                            <img className='mx-auto w-full md:w-3/5' src={indexImage2} alt="img" />
                        </div>
                        <div className='flex-1 text-white text-center md:text-left'>
                            <h3 className='text-3xl md:text-4xl mb-[20px] wow fadeInUp'><span className='font-bold text-theme-color-3 '>美容服務</span> 獨一無二</h3>
                            <div className='wow fadeInUp text-white pt-2'>
                                <p className='text-xl'>融匯嶄新醫美科技，為客人提供專業醫美方案，成就終生美麗。</p>
                            </div>
                            <div className='wow bounceInUp'>
                                <a href="https://api.whatsapp.com/send/?phone=85267776444&text&type=phone_number&app_absent=0" className='effect-cta mt-4'>立即預約</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='bg-white py-[70px] '>
                <div className='section-container max-w-[1300px] '>
                    <h2 className='mb-[40px] text-center text-4xl wow fadeIn text-theme-color-3'>為何選擇我們？</h2>
                    <div className='mt-[50px] grid md:grid-cols-3 gap-[40px] wow bounceIn'>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-4'>
                        <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-theme-color-1 flex justify-center items-center'>
                        <TrophyIcon className='w-12' />
                            </div>
                            <h3 className='mb-[30px] text-theme-color-3 text-2xl'>穴位埋線減肥專家</h3>
                            <p className='text-white text-lg md:text-xl'>Lux Medical皇牌穴位埋線減肥療程，由專業註冊中醫師主理，信心保證！</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-4'>
                        <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-theme-color-1 flex justify-center items-center'>
                        <StarIcon className='w-12' />
                            </div>
                            <h3 className='mb-[30px] text-theme-color-3 text-2xl'>星級之選信心保證</h3>
                            <p className='text-white text-lg md:text-xl'>Lux Medical自創立以來，一直以專業真誠服務態度，配合國際頂尖美容科技，贏得無數顧客信心，同時亦獲得一眾星級客人支持！</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-4'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-theme-color-1 flex justify-center items-center'>
                                <ShieldCheckIcon className='w-12' />
                            </div>
                            <h3 className='mb-[30px] text-theme-color-3 text-2xl'>鑽石級導師主理紋繡服務</h3>
                            <p className='text-white text-lg md:text-xl'>Lux Medical亦錦上添花，於2016年成立國際紋繡學會，由曾榮獲國際多個紋繡大賽殊榮的鑽石級導師親自主理！</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-[70px]'>
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-3'>我們的星級服務</h2>
                <Swiper arrayList={promoSwiper} />
            </section>
            <section className='py-[70px] scroll-mt-[90px]' id='getInTouch'>
                <div className='section-container'>
                    <div className='flex justify-center flex-col md:flex-row'>
                        <div className='md:w-[45%]  flex mb-[50px] px-4 md:px-0 md:mb-0 flex-col wow fadeInLeft'>
                            <div className='mb-[40px] w-full'>
                                <h2 className='mb-4 text-theme-color-3 text-4xl'>聯絡我們</h2>
                                <span className='text-base mb-4'>
                                香港旺角彌敦道579號艾麗大厦地下4號鋪及一樓全層SHOP 4 & 1/F, NO. 579 NATHAN RD, MONG KOK, KLN, HONG KONG。</span>
                                <br />
                                <iframe className='my-4'src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7382.022250642233!2d114.169907!3d22.315419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400c724d57ea7%3A0x4ae19e47a967ed1e!2zTHV4IE1lZGljYWwg6Yar57qW576O!5e0!3m2!1szh-TW!2shk!4v1735657140353!5m2!1szh-TW!2shk" width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                <span className='text-xl'>歡迎直接聯絡我們！</span>
                            </div>
                            <div className='break-words w-full'>
                                <div className="flex">
                                    <SocialMediaShare />
                                </div>
                            </div>

                        </div>
                        <div className='md:w-[45%] max-w-[650px] wow fadeInRight'>
                            <Form />
                        </div>
                    </div>
                </div>


            </section>


        </DocumentMeta>

    )
}
