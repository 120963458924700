import React from 'react'
import DocumentMeta from 'react-document-meta';
import MetaTag from "../../components/MetaTag";
import CardList from '../../components/CardList'
import { beautyQuestionArray, beautyPlanArray, MetaDataList } from '../../components/Data'
import backgroundImage from '../../components/Carousel/images/index-cover1.jpg'
import { useParams } from 'react-router-dom';

export default function Beauty() {
    const pageAlias = 'service-web-development'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service/web-development` });
    const { beauty } = useParams();
    return (
        <DocumentMeta {...meta}>
            <section className='relative md:h-[100vh] pt-[100px] pb-16 md:pb-0'>
                <img className="absolute opacity-50 bg-black inset-0 w-full h-full object-cover -z-10"
                    src={backgroundImage}
                />
                <div className='section-container max-w-[1200px] mx-auto px-[30px] text-center font-bold text-theme-color-3 wow fadeIn'>
                    <h1 className='mb-8 text-5xl'>{beautyPlanArray[beauty].title}</h1>
                </div>
            </section>
            <section className='bg-transparent md:-mt-[500px]'>
                <div className='section-container mx-auto px-[30px]'>
                    <CardList planList={beautyPlanArray[beauty].productImg} />
                </div>
            </section>
            <section className='py-[70px]'>
                <div className='section-container mx-auto px-[30px]'>
                    <div className='flex-col-reverse md:flex-row flex items-center mb-[30px] md:mb-[0px]'>
                        <div className='flex-1 md:mr-[30px] text-center md:text-left wow fadeInLeft'>
                            <h3 className='font-bold pb-4 text-theme-color-3'>{beautyPlanArray[beauty].pageTitle1}</h3>
                            <p className='text-theme-color-5 '>{beautyPlanArray[beauty].pageDetail1}</p>
                        </div>
                        <div className='flex-1 max-w-[500px] md:max-w-full mb-[30px] wow fadeInRight'>
                            <img src={beautyPlanArray[beauty].pageImg[0]} alt="響應式網頁設計" />
                        </div>
                    </div>
                    <div className='flex-col md:flex-row flex items-center mb-[30px] md:mb-[0px]'>
                        <div className='flex-1 max-w-[500px] md:max-w-full mb-[30px] wow fadeInLeft'>
                            <img src={beautyPlanArray[beauty].pageImg[1]} alt="簡單易用的網頁後台管理系統" />
                        </div>
                        <div className='flex-1 md:ml-[30px] text-center md:text-left wow fadeInRight'>
                            <h3 className='font-bold pb-4 mb-[5px] text-theme-color-3'>{beautyPlanArray[beauty].pageTitle2}</h3>
                            <p className=' text-theme-color-5'>{beautyPlanArray[beauty].pageDetail2} </p>
                        </div>

                    </div>

                </div>
            </section>
            {/*
                <section className='bg-theme-color-5 py-[70px]'>
                <div className='section-container max-w-[1300px]'>
                <h2 className='mb-[50px] text-white text-center'>Q&A</h2>
                    {beautyPlanArray[beauty].question.map(({ title, content }) => (
                        <Accordion key={title} title={title} content={content} />
                    ))}
                </div>

                </section>
                */
            }

        </DocumentMeta>
    )
}
