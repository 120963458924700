import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/home";
import AboutUs from "./pages/about-us";
import Error from "./pages/error";

import Treatment from "./pages/treatment";
import Beauty from './pages/beauty';

import WOW from 'wowjs'
import "./index.css";


function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  });
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="*" element={<Error />} />

        <Route path="/beauty/:beauty" element={<Beauty />} />
        <Route path="/treatment/:treatment" element={<Treatment />} />


      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
