import promoThumbnail1 from '../images/promo1.webp'
import promoThumbnail2 from '../images/promo2.webp'
import promoThumbnail3 from '../images/promo3.webp'

import beautyCover1_1 from '../images/beauty1_1.png'
import beautyCover1_2 from '../images/beauty1_2.png'
import beautyCover1_3 from '../images/beauty1_3.png'
import beautyCover1_4 from '../images/beauty1_4.png'
import beautyCover1_5 from '../images/beauty1_5.png'

import beautyCover2_1 from '../images/beauty2_1.png'
import beautyCover2_2 from '../images/beauty2_2.png'
import beautyCover2_3 from '../images/beauty2_3.png'
import beautyCover2_4 from '../images/beauty2_4.png'
import beautyCover2_5 from '../images/beauty2_5.png'

import beautyCover3_1 from '../images/beauty3_1.png'
import beautyCover3_2 from '../images/beauty3_2.png'
import beautyCover3_3 from '../images/beauty3_3.png'
import beautyCover3_4 from '../images/beauty3_4.png'
import beautyCover3_5 from '../images/beauty3_5.png'

import beautyCover4_1 from '../images/beauty4_1.png'
import beautyCover4_2 from '../images/beauty4_2.png'
import beautyCover4_3 from '../images/beauty4_3.png'
import beautyCover4_4 from '../images/beauty4_4.png'
import beautyCover4_5 from '../images/beauty4_5.png'

import beautyCover5_1 from '../images/beauty5_1.png'
import beautyCover5_2 from '../images/beauty5_2.png'
import beautyCover5_3 from '../images/beauty5_3.png'
import beautyCover5_4 from '../images/beauty5_4.png'
import beautyCover5_5 from '../images/beauty5_5.png'

import {
  BriefcaseIcon,
  CursorArrowRaysIcon,
  ComputerDesktopIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import {
  PhoneIcon,
  SquaresPlusIcon,
  SignalIcon,
  MusicalNoteIcon,
  BookOpenIcon,
  SparklesIcon,
  HeartIcon,
} from "@heroicons/react/24/solid";

export const beautyMenuItems = [
  {
    title: "Ellanse 少女針",
    description: "改善淚溝、眼袋、眼尾及淡化法令紋",
    icon: SparklesIcon,
    link: '/beauty/Ellanse-少女針',
  },

  {
    title: "Profhilo 時光逆時針",
    description: "時間逆轉之神",
    icon: SparklesIcon,
    link: '/beauty/Profhilo-時光逆時針',
  },
  {
    title: "Juvederm Voluma 玻尿酸亮肌針",
    description: "美感的密碼",
    icon: SparklesIcon,
    link: '/beauty/Juvederm-Voluma-玻尿酸亮肌針',
  },
  {
    title: "BOTOX 去皺瘦面針",
    description: "緊獎V面塑顏",
    icon: SparklesIcon,
    link: '/beauty/BOTOX-去皺瘦面針',
  },
  {
    title: "Dysport 肉毒桿菌瘦面針",
    description: "發掘熱門的APP推薦。",
    icon: SparklesIcon,
    link: '/beauty/Dysport-肉毒桿菌瘦面針',
  },

];

export const treatmentMenuItems = [
  {
    title: "穴位埋線纖體療程",
    description: "促進新陳代謝",
    icon: HeartIcon,
    link: '/treatment/穴位埋線纖體療程',
  },
  {
    title: "增肌減脂 x 冷凍溶脂療程",
    description: "韓國Balance-Ice II 20分鐘冷凍溶脂療程",
    icon: HeartIcon,
    link: '/treatment/增肌減脂x冷凍溶脂療程',
  },
  {
    title: "冰火溶脂療程",
    description: "Balance Ice II 冷凍溶脂",
    icon: HeartIcon,
    link: '/treatment/冰火溶脂療程',
  },
  {
    title: "皇牌肌底細胞活化療程",
    description: "Profhilo 肌底細胞活化療程",
    icon: HeartIcon,
    link: '/treatment/皇牌肌底細胞活化療程',
  },
  {
    title: "第五代【ROSE V LINE 玫瑰線】無針埋線玫瑰線",
    description: "Lux beauty無針埋線玫瑰線",
    icon: HeartIcon,
    link: '/treatment/第五代【ROSE-V-LINE-玫瑰線】無針埋線玫瑰線',
  },
  {
    title: "小顏術療程",
    description: "瘦面、排毒、通淋巴",
    icon: HeartIcon,
    link: '/treatment/小顏術療程',
  },
];



export const formQuestions = [
  {
    htmlTag: "input",
    rows: "",
    type: "text",
    placeholder: "稱呼 Name",
    name: "name",
    validations: { required: true },
    errorMessages: { required: "請填寫此欄" }
  },
  {
    htmlTag: "input",
    rows: "",
    type: "email",
    placeholder: "電郵地址 Email Address",
    name: "email",
    validations: { required: true, pattern: /^\S+@\S+$/i },
    errorMessages: { required: "請填寫此欄", pattern: "請填寫正確的電郵格式" },
  },
  {
    htmlTag: "input",
    rows: "",
    type: "tel",
    placeholder: "聯絡電話 Phone Number",
    name: "phone",
    validations: { required: true, pattern: /[0-9]/ },
    errorMessages: { required: "請填寫此欄", pattern: "請輸入正確的電話號碼 (只需數字)" },
  },
  {
    htmlTag: "textarea",
    rows: "6",
    type: "message",
    placeholder: "留言 Your Message",
    name: "message",
    validations: { required: true, maxLength: 200 },
    errorMessages: { required: "請填寫此欄", maxLength: "字數上限為200字" },
  }
]

export const commonInfo =
{
  phone: "85267776444",
  email: "info@luxbeauty.hk"
}

export const commonServices = [
  {
    alias: 'web-development',
    name: '網頁設計',
    abstract: '製定合適的網頁設計方案',
    description: '為您度身製作網站，製定合適的網頁設計方案，重視使用者體驗及視覺設計。無論是公司網頁、網上商店、個人化網頁等，都會針對客戶要求設計。',
    href: '/service/web-design',
    icon: ComputerDesktopIcon,
  },
  {
    alias: 'seo',
    name: 'SEO優化',
    abstract: '提昇網站SEO排名',
    description: '幫助提昇網站SEO排名，了解搜尋引擎的自然排名運作原理來優化網站。有效地改善您的網站以獲得更多的瀏覽量，產生更多流量，接觸更多潛在客戶。',
    href: '/service/seo',
    icon: CursorArrowRaysIcon,
  },
  {
    alias: 'digital-marketing',
    name: '數碼營銷',
    abstract: "一站式全面數碼行銷方案",
    description: "一站式全面數碼行銷方案，為各行各業的客戶策劃不同類型的營銷計劃如搜尋引擎推廣(Google Ads)及內容行銷(Content Marketing)等，提升網站曝光率。",
    href: '/service/digital-marketing',
    icon: BriefcaseIcon
  },
]

export const commonContact = [
  { name: '聯絡客務：' + commonInfo.phone, href: '#', icon: PhoneIcon },
  { name: '電郵：' + commonInfo.email, href: '#', icon: EnvelopeIcon },
]




export const treatmentQuestionArray = [
  {
    title: "數碼營銷是甚麼？",
    content: "數碼營銷是指針對使用數碼裝置的用戶作出行銷。透過數位渠道對消費者推廣產品和服務，不需要有實體上的互動都能有效推銷及吸引用戶。"
  },
  {
    title: "數碼營銷有甚麼分類？",
    content: "數碼營銷種類繁多，包括社交媒體營銷、搜尋引擎行銷(SEM)、搜尋引擎優化(SEO)、電子郵件營銷等。由於太多分類，以上3個計劃中未有涵蓋所有數碼營銷手法， 閣下有相關需要都可以免費向我們查詢及索取報價。"
  },
];


export const promoSwiper = [
  {
    thumbnail: promoThumbnail1,
    heading: "真正零毛孔"

  },
  {
    thumbnail: promoThumbnail2,
    heading: "瘦身必做"

  },
  {
    thumbnail: promoThumbnail3,
    heading: "打造水亮BB肌"

  }

];

export const MetaDataList = {
  home: {
    page: 'home',
    title: '香港網頁製作公司',
    description: "香港具規模的網站開發公司，專業團隊，提供一站式的網站設計與製作服務，滿足各種企業的不同建站需求",
    keyword: "網站開發, web design, website design, website builder, web designer, 網站製作, 網頁設計, 網站設計, 網站建設, 創建網站, 網頁製作, 製作網頁, 數碼營銷, SEO, SERP, 網頁排名, Google SEO, 搜尋引擎優化"
  },
  seo: {
    page: 'seo',
    title: '搜尋引擎優化(SEO)服務',
    description: "透過了解搜尋引擎的運作原理後，對網站作出內容和程式碼上的調整，以提升網站的搜尋排名，增加曝光度。",
    keyword: "SEO, SERP, 網頁排名, Google SEO, 搜尋引擎優化",
  },
  digital: {
    page: 'digital-marketing',
    title: '數碼營銷服務',
    description: "數碼營銷有多種不同方法，透過網上宣傳如SEM、社交媒體廣告等，尋找目標客戶，增加網店銷售額。",
    keyword: "數碼營銷, digital marketing",
  },
  develop: {
    page: 'web-development',
    title: '網頁開發服務',
    description: "我們提供專業的網頁開發服務，了解您的需求，由豐富經驗的網頁開發人員為你服務。製作專屬於您的網站，響應式網頁設計令用戶不論以任何電子設備瀏覽網頁都能以最好介面示人。",
    keyword: "網站開發, web design, website design, website builder, web designer, 網站製作, 網頁設計, 網站設計, 網站建設, 創建網站, 網頁製作, 製作網頁",
  },
  design: {
    page: 'design',
    title: '設計服務',
    description: "反映出公司風格的設計，往往能令客戶有深刻的印象。除了網頁設計外，公司標誌、卡片、廣告、EDM等，都是我們服務範圍。透過獨特嶄的設計，吸引目標客戶的注意，令您公司的形象深深刻在他們的腦海中！",
    keyword: "書刊設計,小册子設計, 菜單設計, 商標設計, 橫額設計, 咭片設計, 信封設計, 錦旗設計, 廣告設計, 海報宣傳設計, 單張設計, 坐枱月曆設計",
  },
  contact: {
    page: 'contact',
    title: '聯絡我們',
    description: "任何網頁開發、設計相關、數碼營銷和SEO優化的項目，歡迎直接聯絡我們。我們誠心幫助每一個客戶拓展業務，提供不同種類的技術支援。",
    keyword: "網站開發, web design, website design, website builder, web designer, 網站製作, 網頁設計, 網站設計, 網站建設, 創建網站, 網頁製作, 製作網頁, 數碼營銷, SEO, SERP, 網頁排名, Google SEO, 搜尋引擎優化"
  },
  error: {
    title: '頁面不存在',
    description: "這是404頁面。 您已被重定向至此處，因為您請求的頁面不存在！"
  }
}


export const beautyPlanArray = {

  'Ellanse-少女針': {
    title:'Ellanse 少女針',
    productImg: [beautyCover1_1, beautyCover1_2, beautyCover1_3],
    pageImg: [beautyCover1_4, beautyCover1_5],
    pageTitle1: '5效嫩肌',
    pageDetail1: '豊盈、填補、補濕、重塑、收毛孔',
    pageTitle2: 'Ellanse 少女針 6大特點',
    pageDetail2: '豐盈輪廓、撫平皺紋、重塑輪廓、效果可長達1~4年、30%PCL晶球有助刺激膠原蛋簡再生、70%CMC凝膠立即填補臉部凹陷',
    question: [{
      title: "一站式服務提供甚麼？",
      content: "從程式碼開發、圖像設計，至網域及伺服器申請，我們一手包辦。 閣下可參與網站的用能及頁面設計部份，打造自己心意而獨一無二的理想網站，其他技術支援都可放心交給我們。我們更有專業且完善的SEO優化服務"
    },
    {
      title: "為何要申請網域名稱？",
      content: "網域名稱是屬於網站的獨有名稱，令客戶對你的品牌有一個亮眼的記憶點，往往一個有意義的網域名稱更能反映該網站的品牌理念。因此根據不同的網域名稱，所需的費用都有不同。"
    },
    {
      title: "我已經有自己的網站程式碼，可以幫我架設嗎？",
      content: "可以的，我們可以代為管理網站運作，詳情可向我們查詢。"
    }]
  },
  'Profhilo-時光逆時針': {
    title:'Profhilo 時光逆時針',
    productImg: [beautyCover2_1, beautyCover2_2, beautyCover2_3],
    pageImg: [beautyCover2_4, beautyCover2_5],
    pageTitle1: '5分鐘療程',
    pageDetail1: '持效長達24個月',
    pageTitle2: '増加皮膚水份高達20%',
    pageDetail2: '效果持期是非交聯透明質酸的8倍',
    question: [{
      title: "一站式服務提供甚麼？",
      content: "從程式碼開發、圖像設計，至網域及伺服器申請，我們一手包辦。 閣下可參與網站的用能及頁面設計部份，打造自己心意而獨一無二的理想網站，其他技術支援都可放心交給我們。我們更有專業且完善的SEO優化服務"
    },
    {
      title: "為何要申請網域名稱？",
      content: "網域名稱是屬於網站的獨有名稱，令客戶對你的品牌有一個亮眼的記憶點，往往一個有意義的網域名稱更能反映該網站的品牌理念。因此根據不同的網域名稱，所需的費用都有不同。"
    },
    {
      title: "我已經有自己的網站程式碼，可以幫我架設嗎？",
      content: "可以的，我們可以代為管理網站運作，詳情可向我們查詢。"
    }]
  },
  'Juvederm-Voluma-玻尿酸亮肌針': {
    title:'Juvederm Voluma 玻尿酸亮肌針',
    productImg: [beautyCover3_1, beautyCover3_2, beautyCover3_3],
    pageImg: [beautyCover3_4, beautyCover3_5],
    pageTitle1: '令雙眼時刻亮澤有神',
    pageDetail1: '即時填飽淚溝凹陷，減少黑色素陰影積聚。緊緻眼袋、減淡眼紋',
    pageTitle2: '6大保障.信心可靠',
    pageDetail2: '無疤無痕，立即可回復正常。精準判斷施用位置及深度，療程更安全舒適，反應機率極低',
    question: [{
      title: "一站式服務提供甚麼？",
      content: "從程式碼開發、圖像設計，至網域及伺服器申請，我們一手包辦。 閣下可參與網站的用能及頁面設計部份，打造自己心意而獨一無二的理想網站，其他技術支援都可放心交給我們。我們更有專業且完善的SEO優化服務"
    },
    {
      title: "為何要申請網域名稱？",
      content: "網域名稱是屬於網站的獨有名稱，令客戶對你的品牌有一個亮眼的記憶點，往往一個有意義的網域名稱更能反映該網站的品牌理念。因此根據不同的網域名稱，所需的費用都有不同。"
    },
    {
      title: "我已經有自己的網站程式碼，可以幫我架設嗎？",
      content: "可以的，我們可以代為管理網站運作，詳情可向我們查詢。"
    }]
  },
  'BOTOX-去皺瘦面針': {
    title:'BOTOX 去皺瘦面針',
    productImg: [beautyCover4_1, beautyCover4_2, beautyCover4_3],
    pageImg: [beautyCover4_4, beautyCover4_5],
    pageTitle1: '令人無法抗拒',
    pageDetail1: '針對平額頭紋|眉心紋|魚尾紋及兔仔紋|咀嚼肌|下巴',
    pageTitle2: '5大功效 信心保証',
    pageDetail2: '安全認可專業醫生親自處理，無痛無創，擊退動態紋，可即時化妝/護膚及去除動態紋',
    question: [{
      title: "一站式服務提供甚麼？",
      content: "從程式碼開發、圖像設計，至網域及伺服器申請，我們一手包辦。 閣下可參與網站的用能及頁面設計部份，打造自己心意而獨一無二的理想網站，其他技術支援都可放心交給我們。我們更有專業且完善的SEO優化服務"
    },
    {
      title: "為何要申請網域名稱？",
      content: "網域名稱是屬於網站的獨有名稱，令客戶對你的品牌有一個亮眼的記憶點，往往一個有意義的網域名稱更能反映該網站的品牌理念。因此根據不同的網域名稱，所需的費用都有不同。"
    },
    {
      title: "我已經有自己的網站程式碼，可以幫我架設嗎？",
      content: "可以的，我們可以代為管理網站運作，詳情可向我們查詢。"
    }]
  },
  'Dysport-肉毒桿菌瘦面針': {
    title:'Dysport 肉毒桿菌瘦面針',
    productImg: [beautyCover5_1, beautyCover5_2, beautyCover5_3],
    pageImg: [beautyCover5_4, beautyCover5_5],
    pageTitle1: '一針變靚 立體自然',
    pageDetail1: 'DYSPORT 肉毒榫菌素效果持久，快速見效',
    pageTitle2: '100%醫生主理',
    pageDetail2: '由經驗豐富的醫生團隊親自處理，原廠正貨劑量保證',
    question: [{
      title: "一針變靚 立體自然",
      content: "從程式碼開發、圖像設計，至網域及伺服器申請，我們一手包辦。 閣下可參與網站的用能及頁面設計部份，打造自己心意而獨一無二的理想網站，其他技術支援都可放心交給我們。我們更有專業且完善的SEO優化服務"
    },
    {
      title: "為何要申請網域名稱？",
      content: "網域名稱是屬於網站的獨有名稱，令客戶對你的品牌有一個亮眼的記憶點，往往一個有意義的網域名稱更能反映該網站的品牌理念。因此根據不同的網域名稱，所需的費用都有不同。"
    },
    {
      title: "我已經有自己的網站程式碼，可以幫我架設嗎？",
      content: "可以的，我們可以代為管理網站運作，詳情可向我們查詢。"
    }]
  },




};
export const treatmentPlanArray = {
  '穴位埋線纖體療程': {
    iframe:'https://www.youtube.com/embed/OwFVjk060Jc?si=DfZ5J97jpyZD2EU5',
    title:'穴位埋線纖體療程',
    pageTitle1: '穴位埋線可說是「針灸」的延伸',
    pageDetail1: '將「羊腸線」（可被人體吸收的蛋白質組織）埋入體內',
    pageTitle2: '不停地刺激該穴位',
    pageDetail2: '一天24小時都在針灸一樣，在相應的穴位埋入羊腸線透過刺激人體穴道、加強新陳代謝、降低食慾、疏通腸經 (排毒素)、打通要穴、消除浮腫、同時促進血液循環',
    pageTitle3: '醫師療程',
    pageDetail3: '醫師進行療程前通過把脈診症，根據客人的個人體質對症選穴',
    pageTitle4: '旺盛新陳代謝',
    pageDetail4: '使全身脂肪開始燃燒，促進新陳代謝，控制食欲，調理身體，排出垃圾',  
  },
  '增肌減脂x冷凍溶脂療程': {
    iframe:'',
    title:'增肌減脂 x 冷凍溶脂療程',
    pageTitle1: '重奪苗條身材',
    pageDetail1: '加快卡路里燃燒速度',
    pageTitle2: '冷凍溶脂療程',
    pageDetail2: '360度零死角消脂',
    pageTitle3: '排走積聚體內毒素',
    pageDetail3: '溶解皮下脂肪及內臟脂肪',
    pageTitle4: '增加整體肌肉新陳代謝',
    pageDetail4: '達至36,000次肌肉收縮',
    
  },
  '冰火溶脂療程': {
    iframe:'https://www.youtube.com/embed/RMaieUetfjE?si=74fDQKbDIxw7ZfcC',
    title:'冰火溶脂療程',
    pageTitle1: '了解客人需要改善部位',
    pageDetail1: '人手推脂',
    pageTitle2: 'Balance Ice II 冷凍溶脂',
    pageDetail2: '消脂修形一次過攪掂',
    pageTitle3: 'Dr. Rainbow 遠紅外線太空艙',
    pageDetail3: '加強減脂效果',
    pageTitle4: '75分鐘快速有效',
    pageDetail4: '療程針對大脾、臀部、腰',
  },
  '皇牌肌底細胞活化療程': {
    iframe:'',
    title:'皇牌肌底細胞活化療程',
    pageTitle1: '改善皮膚紋理',
    pageDetail1: '可以幫助平滑皮膚表面，減少紋路和不均勻的紋理，讓肌膚看起來更均勻和光滑',
    pageTitle2: '增強皮膚保濕能力',
    pageDetail2: '提供皮膚所需的水分和保護屏障，以確保肌膚保持水潤和彈性，減少乾燥和粗糙的情況',
    pageTitle3: '提升肌膚彈性',
    pageDetail3: '通過促進膠原蛋白和彈性蛋白的生成，可以使肌膚更有彈性和緊緻感，減少皺紋和松弛的問題',
    pageTitle4: '即時達到緊緻肌膚的效果',
    pageDetail4: '使用具有瞬時提拉效果的護膚品或技術，可以使肌膚在短時間內看起來更緊緻、更年輕',
    
  },
  '第五代【ROSE-V-LINE-玫瑰線】無針埋線玫瑰線': {
    iframe:'https://www.youtube.com/embed/0fbE648EP6w?si=W0xbgp8UffAsogsH',
    title:'第五代【ROSE V LINE 玫瑰線】無針埋線玫瑰線',
    pageTitle1: '撫平面上皺紋',
    pageDetail1: '儀器提供精細至2.0毫米深的探頭，針對性更高，能精準地加熱目標位置，每一發能量輸出比舊式無針埋線緊密度高出五倍，可有效促進真皮層增生，撫平皺紋和細緻毛孔',
    pageTitle2: '⁠緊緻拉提面部輪廓',
    pageDetail2: '獨特的MDT（Multiple Duration Technology）聚散緊密式聚焦技術，以12Hz發放超高密能量，形成隱形能量線埋入真皮層及SMAS筋膜層，為皮膚締造緊緻拉力網，促進真皮層膠原再生，恢復肌膚彈性，從內而外極緻提拉，及提升蘋果肌，收緊輪廓線條同時收緊雙下巴，令肌膚變得緊緻及富有彈性，更加年輕。',
    pageTitle3: '提眉亮眼',
    pageDetail3: '無針埋線在刺激膠原增生方面的功效顯著，其圓柱型探頭。可照顧不同的微細部位，如眼袋、眼角等位置，可以貼近眼部，針對眼周鬆弛問題，做到即時收緊眼袋、減淡黑眼圈及魚尾紋，效果十分顯著。',
    pageTitle4: '無痛拉提技術',
    pageDetail4: '內置智能監察能量輸出，確保每一發能量的穩定及精準輸出，可加熱目標位置同時不傷周邊組織，過程無需使用麻醉膏，告別傳統HIFU無針埋線療程的灼熱感。',
    
  },
  '小顏術療程': {
    iframe:'https://www.youtube.com/embed/1nPWQlo4ZOs?si=E7oP6sDKLzQUlls_',
    title:'小顏術療程',
    pageTitle1: '臉部排毒瘦面',
    pageDetail1: '繃緊的肌肉會使臉部顯大，透過獨特的按摩手法放鬆臉部肌肉，改善血液循環，排走臉部淋巴毒素，使面頰和下巴線條更加明顯。按摩額頭肌肉能改善抬頭紋和帶動眼眉的提升。按摩鼻側也很能改善法令紋的問題。重塑面部平整度能達到瘦面消腫的效果',
    pageTitle2: '矯正臉部骨骼',
    pageDetail2: '小顏術的原理是徒手將臉部每塊臉骨中間的空位推緊，做到小顏效果，隨著生活習慣臉部骨頭會移位，做成大細臉等問題，小顏術可以將移位的骨頭矯正，收窄顴骨/腮骨、拉長/縮短下巴及下顎骨、改善包包面/大細面/蘋果肌下垂等問題',
    pageTitle3: '安全無風險',
    pageDetail3: '小顏術全程純人手操作，由於不涉及任何動刀和入侵性療程，過程百分百安全，亦無需任何恢復期和術後護理，亦會產生後遺症。療程後可即時上妝，對生活毫無影響',
    pageTitle4: '⁠效果自然不易反彈',
    pageDetail4: '由於沒有進行削骨或填充任何假體，原理是透過矯正面部自身形狀，使五官和輪廓更加立體，所以不會有異物感，效果真實自然，亦不需反覆加固也很好地維持',
    
  },
}

export const basicSpecificationArray = {
  'phone_brand': '品牌',
  'announce_date': '發表日期',
  'release_date': '推出日期',
  'price': '價格',
  'form_factor': '型狀',
  'network': '網絡系統',
  'cpu': '中央處理器',
  'gpu': '圖像處理器',
  'os': '操作系統及版本',
  'color': '機身顏色',
  'dimensions': '尺寸',
  'weight': '重量',
  'navigation': '操控',
  'fingerprint': '指紋功能',
  'battery_type': '電池種類',
  'removeable_battery': '可更換電池',
  'talk_time': '通話時間',
  'standby_time': '備用時間',
  'ram': 'RAM 容量',
  'rom': 'ROM 容量',
  'expandable_memory': '可擴充記憶',
  'max_memory_size': '最大支援記憶體',
}

export const monitorSpecificationArray = {
  'screen_size': '螢幕尺寸',
  'screen_type': '螢幕類別',
  'screen_resolution': '螢幕解像度',
  'screen_remark': '螢幕備註',
}


export const cameraSpecificationArray = {
  'camera': '數碼相機',
  'pixels': '像素',
  'autofocus': '自動對焦',
  'optical_stablizer': '光學防震',
  'optical_zoom': '光學變焦',
  'digital_zoom': '數碼變焦',
  'conference_cam': '視像鏡頭',
  'flash': '閃光燈',
  'other_cameras_features': '其他相機功能',
  'video': '短片拍攝',
  'video_resolution': '短片解像度',
  'video_zoom': '短片變焦',
}

export const multimediaSpecificationArray = {
  'music_player': '音樂播放格式',
  'video_player': '短片播放格式',
  'radio_player': '收音機',
  'streaming': '串流播放格式',
  'voice_command': '聲控功能',
  'incoming_alert': '來電提示',
  'application_download': 'Apps 下載',
}

export const connectivitySpecificationArray = {
  'nfc': 'NFC',
  'bluetooth': '藍牙支援',
  'infrared': '紅外線支援',
  'mobile_data': '流動數據',
  'wifi': 'wifi 功能',
  'gps': 'gps 功能',
  'pc': '電腦連線',
  'tvout': '視頻輸出',
}
export const mobileBrandArray = {
  "apple": "Apple",
  "samsung": "Samsung",
  "sony": "Sony",
  "microsoft": "Microsoft",
  "nokia": "Nokia",
  "google": "Google",
  "xiaomi": "Xiaomi",
  "huawei": "Huawei",
  "oppo": "OPPO",
  "vivo": "Vivo",
  "oneplus": "OnePlus",
  "asus": "ASUS",
  "lg": "LG",
  "htc": "HTC",
  "motorola": "Motorola",
  "blackberry": "BlackBerry",
  "lenovo": "Lenovo",
  "alcatel": "Alcatel",
  "acer": "Acer",
  "tcl": "TCL",
  "meizu": "Meizu",
  "sony-ericsson": "Sony Ericsson",
  "sharp": "Sharp",
  "toshiba": "Toshiba",
  "panasonic": "Panasonic",
  "zte": "ZTE",
  "hp": "HP",
  "dell": "Dell",
  "nec": "NEC",
  "palm": "Palm",
  "vertu": "Vertu",
  "razer": "Razer",
  "vodafone": "Vodafone",
  "caterpillar": "Caterpillar",
  "meitu": "Meitu",
  "honor": "HONOR",
  "inq": "INQ",
  "jolla": "Jolla",
  "k-touch": "K-Touch",
  "kodak": "Kodak",
  "ulefone": "Ulefone",
  "sugar": "Sugar",
  "smartisan": "Smartisan",
  "infocus": "InFocus",
  "gsmart": "GSmart",
  "freetel": "Freetel",
  "onkyo": "Onkyo",
  "nothing": "Nothing",
  "realme": "Realme",
  "letv": "Letv",
  "porsche-design": "Porsche Design"
};


export const tabletBrandArray = {
  "apple": "Apple",
  "samsung": "Samsung",
  "archos": "Archos",
  "honor": "HONOR",
  "huawei": "HUAWEI",
  "lenovo": "Lenovo",
  "microsoft": "Microsoft",
  "nokia": "Nokia",
  "oppo": "OPPO",
  "xiaomi": "Xiaomi",
  "acer": "Acer",
  "alcatel": "Alcatel",
  "arnova": "Arnova",
  "asus": "ASUS",
  "blackBerry": "BlackBerry",
  "dell": "Dell",
  "fujitsu": "Fujitsu",
  "google": "Google",
  "hp": "HP",
  "htc": "HTC",
  "lg": "LG",
  "motorola": "Motorola",
  "sony": "Sony",
  "toshiba": "Toshiba",
  "viewsonic": "ViewSonic",
  "zte": "ZTE",
};




export const recommendArray = {
  'music': {
    title: '熱門Apple Music推介',
    icon: MusicalNoteIcon,
    type: {
      'albums': {
        title: 'Albums',
        feed: {
          'most-played': 'Most Played'
        }
      },
      'music-videos': {
        title: 'Music Videos',
        feed: {
          'most-played': 'Most Played'
        }
      },
      'playlists': {
        title: 'Playlists',
        feed: {
          'most-played': 'Most Played'
        }
      },
      'songs': {
        title: 'Songs',
        feed: {
          'most-played': 'Most Played'
        }
      },
    }

  },
  'podcasts': {
    title: '熱門Podcast推介',
    icon: SignalIcon,
    type: {
      'podcasts': {
        title: 'Podcasts',
        feed: {
          'top': 'Top',
          //'top-subscriber': 'Top Subscriber'
        }
      },
      /*
      'podcasts-episodes': {
        title: 'Podcasts Episodes',
        feed: {
          'top': 'Top',
        }
      },
      'podcasts-channels': {
        title: 'Podcasts Channels',
        feed: {
          'top-subscriber': 'Top Subscriber'
        }
      },
      */
    }
  },
  'apps': {
    title: '熱門APP推介',
    icon: SquaresPlusIcon,
    type: {
      'apps': {
        title: 'Apps',
        feed: {
          'top-free': 'Top Free',
          'top-paid': 'Top Paid'
        }
      },
    }
  },
  'books': {
    title: '熱門Apple Book推介',
    icon: BookOpenIcon,
    type: {
      'books': {
        title: 'Books',
        feed: {
          'top-free': 'Top Free',
          //'top-paid': 'Top Paid'
        }
      },
    }
  },
  /*
  'audio-books': {
    title: '熱門Apple Audio Book推介',
    icon: SpeakerWaveIcon,
    type: {
      'audio-books': {
        title: 'Audio Books',
        feed: {
          'top':'Top',
          '1355215958': 'Everyday listens for $9.99',
          '1461817928': '$9.99 Fiction & Literature',
          '1461817926': '$9.99 Mysteries & Thrillers',
          '1461817931': '$9.99 Nonfiction',
          '1461817929': '$9.99 Biographies & Memoirs',
          '1461817927': '$9.99 Business & Self-Development',
          '1461817933': '$9.99 Romance',
          '1461817932': '$9.99 Sci-Fi & Fantasy',
          '1461817930': '$9.99 Kids & Young Adults',
        }
      },
    }
  }
  */
}

export const metaTag = {
  commonTitle: 'luxbeauty.hk | 網頁開發及電子資訊資料庫',
  commonKeywords: 'luxbeauty',
  'mobile-blog-detail': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-blog': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-brand-list': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-home': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-product-detail': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-product-detail': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-product-list': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-recommend-list': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'mobile-recommend-detail': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'tablet-home': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'tablet-brand-list': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'tablet-product-detail': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'tablet-product-list': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'service-digital-marketing': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'service-home': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'service-seo': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'service-web-design': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
  'service-web-development': {
    title: '醫美針劑',
    description: '醫美針劑',
    keywords: '醫美針劑',
  },
}
