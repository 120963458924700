import React from 'react'

export default function CardList(planList) {
    return (
        <div className='md:section-container mx-auto'>
            <div className=' wow fadeInUp'>
                {planList.planList.map((value, index) => {
                    return (
                        <div key={index} className={`mt-[30px]  md:mt-0 w-4/5 mx-auto bg-[#f7f7f7] p-[20px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.1)] text-center first:z-[10]`}>
                        <img src={value} />
                    </div>
                    )
                })}
            </div>
            <div className='relative group my-[50px] w-fit mx-auto wow rubberBand'>
            <a href="https://api.whatsapp.com/send/?phone=85267776444&text&type=phone_number&app_absent=0" className='effect-cta-3 flex items-center w-fit mx-auto bg-theme-color-5'>立即 Whatsapp 聯絡我們
                <div className='transition-all -ml-[20px] invisible opacity-0 group-hover:visible group-hover:opacity-100 group-hover:ml-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </div>
            </a>
            </div>
        </div>
    )
}
