import { useRef } from "react"
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { commonInfo, formQuestions } from "./Data";

export default function Form() {
  const form = useRef();
  const sendToEmail = (e) => {
     emailjs.sendForm(process.env.REACT_APP_EMAILJS_GMAIL_SERVICE_ID, process.env.REACT_APP_EMAILJS_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY).then(
        (result) => {
           alert("感謝您的查詢，我們會有專人儘快與您聯絡。");
        },
           (error) => {
           alert(`抱歉！未能提交查詢，請聯絡${commonInfo.phone}跟進。`);
        }
     );
  };

  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const onSubmit = data => {
     sendToEmail()
     reset()
  };

  const errorMessage = //for textarea input
  {
     required: '請填寫此欄',
     max200: '字數上限為200字',
  }

  const errorStyle = (condition) => {
     if (condition) {
      return {
         backgroundColor: '#fbecf2',
         border: '1px solid #ec5990',
         borderWidth: '1px 1px 1px 10px',
         transition: 'all .3s'

      }
     }

  };

  return (
<div className="w-full px-4">
            <div className="bg-white relative rounded-lg p-8 sm:p-12 shadow-lg">
               <form onSubmit={handleSubmit(onSubmit)} ref={form} noValidate>
                  {formQuestions.map((question) => (
                     <div key={question.name} className="mb-6">
                     <question.htmlTag
                        row = {question.rows}
                        type = {question.type}
                        placeholder= {question.placeholder}
                        className="w-full rounded py-3 px-4 text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary "
                        style={errorStyle(errors[question.name])}
                        name={question.name}
                        {...register(question.name, question.validations)}
                        />
                        {Object.keys(question.validations).map((validation, index) => (
                           errors[question.name]?.type === validation && <span key={index} className="block mt-2 text-red-600 text-xs">{question.errorMessages[validation]}</span>
                        ))}
                     </div>
                     ))}
                  <div>
                     <button
                        type="submit"
                        className="
                        w-full
                        text-white
                        bg-theme-color-4
                        rounded
                        border border-primary
                        p-3
                        transition
                        hover:bg-opacity-90
                        "
                        >
                     確認提交
                     </button>
                  </div>
               </form>
            </div>
         </div>
  )
}
