import React from 'react'
import DocumentMeta from 'react-document-meta';
import Slider from '../components/Carousel/Slider'
import MetaTag from "../components/MetaTag";
import Form from '../components/Form'
import SocialMediaShare from '../components/SocialMediaShare'
import Swiper from '../components/Swiper'
import { promoSwiper} from '../components/Data'
import indexImage1 from '../images/indexImage1.jpg'
import indexImage2 from '../images/indexImage2.jpg'
import { ShieldCheckIcon, StarIcon, TrophyIcon } from '@heroicons/react/24/outline'


export default function AboutUs() {
    const pageAlias = 'service-home'
    const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `service` });

    return (
        <DocumentMeta {...meta}>
          

            <section className="bg-white bg-no-repeat bg-cover bg-scroll bg-[50%_0] container mx-auto py-8 mt-16">
                <div className='section-container mx-auto px-[30px]'>
                    <div className=' flex-col-reverse md:flex-row flex items-center justify-between'>
                        <div className='flex-1 text-center md:text-left'>
                            <h2 className='text-3xl md:text-4xl mb-[20px] wow fadeInLeft'><span className='font-bold text-theme-color-4 '>Lux Medical</span> 醫纖美</h2>
                            <div className='wow fadeInLeft '>
                                <p className='text-xl'>創立於2011年，薈萃頂尖中西醫美科技，不單擁有專業西醫及中醫團隊，旗下治療師團隊均擁國際認證專業證書，</p>
                                <p className='text-xl'>融匯嶄新醫美科技，為客人提供專業醫美方案，成就終生美麗。</p>
                            </div>

                            <div className='wow fadeInUp'>
                                <a href="https://api.whatsapp.com/send/?phone=85267776444&text&type=phone_number&app_absent=0" className='effect-cta mt-4'>立即預約</a>
                            </div>
                        </div>
                        <div className='flex-1 md:ml-[30px] mb-[30px] md:mb-0 wow zoomIn'>
                            <img className='mx-auto w-full md:w-3/5 ' src={indexImage1} alt="img" />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="relative bg-white text-theme-blue py-[70px] text-center">
            <div className='section-container mx-auto px-[20px]'>
                <h2 className='mb-[50px]'>What we do best</h2>
                <div className='flex flex-wrap justify-center'>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={seoLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>SEO<br/>marketing</p>
                    </div>

                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={digitalMarketingLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Digital<br/>marketing</p>
                    </div>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={designLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Logo<br/>design</p>
                    </div>
                    <div className='mb-[20px] px-[30px] md:px-[50px]'>
                        <div className='mb-[20px]'>
                            <img className='w-[100px] h-[100px]' src={webDesignLogo} alt="icon"></img>
                        </div>
                        <p className='font-bold text-black'>Web<br/>design</p>
                    </div>
                </div>
            </div>
        </section> */}
          
           

            <section className='py-[70px] '>
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-3'>我們的星級服務</h2>
                <Swiper arrayList={promoSwiper} />
            </section>
            <section className='py-[70px] scroll-mt-[90px]' id='getInTouch'>
                <div className='section-container'>
                    <div className='flex justify-center flex-col md:flex-row'>
                        <div className='md:w-[45%]  flex mb-[50px] px-4 md:px-0 md:mb-0 flex-col wow fadeInLeft'>
                            <div className='mb-[40px] w-full'>
                                <h2 className='mb-4 text-theme-color-3 text-4xl'>聯絡我們</h2>
                                <span className='text-base mb-4'>
                                香港旺角彌敦道579號艾麗大厦地下4號鋪及一樓全層SHOP 4 & 1/F, NO. 579 NATHAN RD, MONG KOK, KLN, HONG KONG。</span>
                                <br />
                                <iframe className='my-4'src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7382.022250642233!2d114.169907!3d22.315419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400c724d57ea7%3A0x4ae19e47a967ed1e!2zTHV4IE1lZGljYWwg6Yar57qW576O!5e0!3m2!1szh-TW!2shk!4v1735657140353!5m2!1szh-TW!2shk" width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                <span className='text-xl'>歡迎直接聯絡我們！</span>
                            </div>
                            <div className='break-words w-full'>
                                <div className="flex">
                                    <SocialMediaShare />
                                </div>
                            </div>

                        </div>
                        <div className='md:w-[45%] max-w-[650px] wow fadeInRight'>
                            <Form />
                        </div>
                    </div>
                </div>


            </section>


        </DocumentMeta>

    )
}
