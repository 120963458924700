import React from 'react'

export default function VideoContainer(video) {
    return (
        <div className='md:section-container mx-auto'>
            <div className='wow fadeInUp'>

                <div className='mt-[30px] md:mt-0 w-full  bg-[#f7f7f7] p-[20px] shadow-[0_2px_20px_0px_rgba(0,0,0,0.1)] min-h-96'>
                    <div className='relative my-[30px] mx-auto w-full h-auto '>
                        <iframe className='w-full h-auto  min-h-96' height="auto" src={video.planList} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <div className='relative group my-[30px] w-fit mx-auto'>
                        <a href="https://api.whatsapp.com/send/?phone=85267776444&text&type=phone_number&app_absent=0" className='effect-cta-3 bg-theme-color-5  flex items-center w-fit mx-auto'>查詢更多
                            <div className='transition-all -ml-[20px] invisible opacity-0 group-hover:visible group-hover:opacity-100 group-hover:ml-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                </svg>
                            </div>
                        </a>

                    </div>

                </div>
            </div>
        </div>
    )
}
